import React from 'react'
import styled from 'styled-components';
import { useState } from "react";
import { useTimer } from '../hooks/useTimer';

//#region Styles
const Container = styled.div`
    text-align: center;
    margin-left: 10%;
    margin-right: 10%;
    margin-top: 0rem;
`; 

const ImageContainer = styled.div`
    width: fit-content
    margin-left: auto;
    margin-right: auto;
    color: white;
    text-align: center;
    background-color: #717171;
    
`; 

const Image = styled.img`
    width: 100%;
    height: auto;
    margin-top: 5rem;

    @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation:portrait) {
        /* For portrait layouts only */
        margin-top: 2rem;
        width: 100%;
        height: auto;
      }
    @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation:landscape) {
        /* For landscape layouts only */
        margin-top: 2rem;
        width: 100%;
        height: auto;
    }
`; 


const ImageObstacles = styled.img`
@media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation:landscape) {
    /* For landscape layouts only */
    position: absolute;
    left: 60%;
    top: 5rem;
    width: 30%;
    height: auto;
  }

  @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation:portrait) {
    /* For portrait layouts only */
    margin-top: 1rem;
    margin-left: 10rem;
    width: 60%;
    height: auto;
  }
  margin-top: 1rem;
  margin-left: 11rem;
  width: 30%;
  height: auto;
   
`; 

const ButtonContainer = styled.div`
    margin-top: 2rem;
    
`; 

const Button = styled.button`
    vertical-align: top;
    color: white;
    margin-left: 3rem;
    width: 120px; 
    height: 70px;
    font-size: 20px;
    font-family: 'ForsvarsmaktenSans-Stencil';
    border: 2px solid #ffef00;
    border-radius: 10px;
    background-color: #717171;
    &:hover{
        cursor: pointer;
        background-color: #ffef00;
    }
`;

const ButtonSub = styled(Button)`
    margin-left: 0px;
`;

const ButtonStart = styled(Button)`
&:hover{
    cursor: pointer;
    color: white;
    background-color: #ffef00;
    border: 2px solid #ffef00;
}

`;

const Select = styled.select`
    -webkit-appearance: none;
    background-color: transparent;
    background-image: url(assets/images/select.png);
    background-repeat: no-repeat;
    background-position: right;
    background-size: 40px;
    vertical-align: top;
    padding-left: 1.5rem;
    width: 120px; 
    height: 70px;
    font-family: 'ForsvarsmaktenSans-Stencil';
    font-size: 20px;
    color: white;
    background-color: #717171;
    border: 2px solid #ffef00;
    border-radius: 10px;
    &:hover{
        cursor: pointer;
    }
`; 

const Title = styled.h1`
    -webkit-appearance: none;
    color: white;
    font-size: 55px;
    margin-bottom: 0rem;
`; 

const SubTitle = styled.h2`
    margin-top: 3rem;
    margin-left: 20%;
    margin-right: 20%;
    color: white;
    font-size: 40px;

`; 

const SubSubTitle = styled.h2`
    margin-top: 1rem;
    margin-left: 20%;
    margin-right: 20%;
    color: white;
    font-size: 20px;
`; 


const InformationTextContainer = styled.div`
@media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation:portrait) {
    /* For portrait layouts only */
    color: white;
    display: flex;
    margin-left: 0rem;
    margin-right: 0rem;
    justify-content: space-between;

  }
    color: white;
    display: flex;
    margin-left: 0rem;
    margin-right: 0rem;
    justify-content: space-between;
`; 

const InformationText = styled.h2`
    color: white;


`; 

const StyledTimer = styled.h2`
    color: white;
    display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: center;
	align-items: stretch;
	align-content: stretch;
`; 

const TimerStep2 = styled.h2`
    width: 100%;
    display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: center;
	align-items: stretch;
	align-content: stretch;
    color: white;
    font-size: 150px;

`; 

const Icon = styled.img`
    @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation:landscape) {
        /* For landscape layouts only */
        width: 45%;
        height: auto;
        }
    @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation:portrait) {
        /* For portrait layouts only */
        margin-top: 2rem;
        width: 80%;
        height: auto;
    }
    width: 50%;
    height: auto;
    
`; 

const Minute1 = styled.div` 
    width: 6rem;
`; 

const Minute2 = styled.div` 
    width: 6rem;
`; 

const Second1 = styled.div`
    width: 6rem;
    
`; 

const Second2 = styled.div`
    width: 6rem;
`;

const Minute12 = styled.div` 
    width: 13px;
`; 

const Minute22 = styled.div` 
    width: 13px;
`; 

const Second12 = styled.div`
    width: 13px;
    
`; 

const Second22 = styled.div`
    width: 13px;
`;

const StyledList = styled.div`
    text-align: left;
    li {
        padding: 10px 0px;
    }
`;

const FMLogo = styled.div`
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;

  margin: 1rem 7rem 0;

  img {
    max-height: 6.5rem;
  }

  @media only screen and (max-width: 1250px) {
    margin: 1rem 6rem 0;
    img {
      max-height: 6rem;
    }
  }
  @media only screen and (max-width: 1000px) {
    position: relative;
    margin: 0;
    text-align: left;
    img {
      max-height: 4rem;
    }
  }
`;
//#endregion

const Competition = () => {
    const [isActiveCompetition, setIsActiveCompetition] = useState(false)
    const [hasCompetitionEnded, setHasCompetitionEnded] = useState(false)
    const [showAnswer, setShowAnswer] = useState(false)
    const [imageURL, setImageURL] = useState('')
    const [difficulty, setDifficulty] = useState(1)
  
    const initialState = false;
    
    const [step2, setStep2] = useState(initialState);

    const inTimerCallback = (timeleft) => {
      // Setup sound cues
      let beepSound = new Audio("assets/sounds/beep1.mp3")
      let horseSound = new Audio("assets/sounds/horseneigh.mp3")

      if(timeleft <= 5 && timeleft > 0){
        beepSound.play()
      }
      if(timeleft === 1){
        horseSound.play()
      }
    }
    

    const step1EndCallback = () => {
      continueGame();
    }

    const step1Timer = useTimer({
      initialTime: 30,
      callback: step1EndCallback,
      inTimerCallback
    });

    const step2EndCallback = () => {
      endCompetition();
    }

    const step2Timer = useTimer({
      initialTime: 60,
      callback: step2EndCallback,
      inTimerCallback
    });

    const startCompetition = () => {
        // Load random image
        const min = 1;
        const max = 10;
        const rand = Math.floor(Math.random() * (max - min + 1) + min);
        const img_url = `assets/images/${rand}.png`

        setImageURL(img_url)
        setIsActiveCompetition(true)
        step1Timer.start();
    }

    const playHorseSound = () => {
      const audio = new Audio("assets/sounds/horseneigh.mp3");
      console.log('Should play horse sound');
      audio.currentTime = 1;
      audio.play();
    }

    const continueGame = (horseSound = false) => {
      if (horseSound) playHorseSound();
      step1Timer.stop(true);
      setStep2(true)
      step2Timer.start();
    }

    const endCompetition = (horseSound = false) => {
      if (horseSound) playHorseSound();

      step2Timer.stop(true);
      // Show answer button
      setIsActiveCompetition(false)
      setHasCompetitionEnded(true)
      setStep2(false);
    }

    const showAnswerFunction = () =>{
        // Show answer picture
        setShowAnswer(true)
    }

    const reset = () =>{
        // Show answer picture
        setIsActiveCompetition(false)
        setHasCompetitionEnded(false)
        setStep2(false)
        setImageURL('')
        setShowAnswer(false)
        setDifficulty(parseInt(difficulty))
    }

    // When step1Timer runs out it will not go back to its inital value. 
    // this is a fast solution.
    const handleTimeBug = () => {
        if (step1Timer.timeLeft % 2 !== 0){
          step1Timer.timeLeft++
          }
    }

    const handleDifficulty = (e) =>{
       
        setDifficulty(e.target.value)
        // Handle Difficulty setting, How much time the user has to memorize the picture
        switch(+e.target.value) {
            case 1:
              step1Timer.setInitialTime(30);
            break;
            case 2:
              step1Timer.setInitialTime(20);
            break;
            case 3:
              step1Timer.setInitialTime(10);
            break;
            default:
              step1Timer.setInitialTime(99);
            break;
        }
    }

    return (
        <Container>
            {!isActiveCompetition && !hasCompetitionEnded && !showAnswer &&
              <div>
             {handleTimeBug()}
              <FMLogo>
                <a href='https://www.forsvarsmakten.se/'>
                  <img src='assets/images/FM_logo_top.png' alt='' />
                </a>
              </FMLogo>
                <Icon src={'assets/images/HorseHead.png'}></Icon>
                <Title>HAR DU MINNE SOM EN HÄST?</Title>
                <SubSubTitle>
                    <StyledList>
                        <ol>
                            <li>Du kommer under {step1Timer.timeLeft} sekunder få se en bild på en hoppbana där du ska memorera hindrens placering.</li>
                            <li>Placera sedan hindren utifrån minnet på den fysiska hoppbanan.</li>
                            <li>Personalen bedömmer antal korrekta placeringar.</li>
                            <li>Du får poäng för varje rätt placerat hinder.</li>
                        </ol>
                    </StyledList>
                    
                </SubSubTitle>
                <ButtonContainer>
                    <Select
                        name="difficulty"
                        id=""
                        value={difficulty}
                        onChange={handleDifficulty}
                    >
                        <option value={1}>Nivå 1</option>
                        <option value={2}>Nivå 2</option>
                        <option value={3}>Nivå 3</option>
                    </Select>

                    <ButtonStart onClick={() => startCompetition()}>Starta</ButtonStart>
                </ButtonContainer>
              </div>
            }
           
            {!hasCompetitionEnded && !showAnswer && !step2 && isActiveCompetition &&
              <div>
                  <Title>HAR DU MINNE SOM EN HÄST?</Title>
                  <SubSubTitle>TESTA DIN FÖRMÅGA. PLACERA UT HINDREN ENLIGT KARTAN NEDAN.</SubSubTitle>
                  <ImageObstacles src={'assets/images/hinderv2.png'}></ImageObstacles>
                  <ImageContainer>
                      <Image src={imageURL}></Image>
                      <InformationTextContainer>
                          <InformationText>NIVÅ {difficulty}</InformationText>
                          <StyledTimer>
                              <Minute12>{new Date(step1Timer.timeLeft * 1000).toISOString().substr(14, 1)}</Minute12>
                              <Minute22>{new Date(step1Timer.timeLeft * 1000).toISOString().substr(15, 1)}</Minute22>
                              :
                              <Second12>{new Date(step1Timer.timeLeft * 1000).toISOString().substr(17, 1)}</Second12>
                              <Second22>{new Date(step1Timer.timeLeft * 1000).toISOString().substr(18, 1)}</Second22>
                          </StyledTimer>
                      </InformationTextContainer>
                  </ImageContainer>
                  <ButtonContainer>
                    <ButtonSub onClick={() => continueGame(true)}>Fortsätt</ButtonSub>
                  </ButtonContainer>
              </div>
            }
           
            
            {step2 && !showAnswer && !hasCompetitionEnded &&
              <div>
                  <Title>HAR DU MINNE SOM EN HÄST?</Title>
                  <SubTitle>Placera ut hindren på banan.</SubTitle>
                  <TimerStep2>
                      <Minute1>{new Date(step2Timer.timeLeft * 1000).toISOString().substr(14, 1)}</Minute1>
                      <Minute2>{new Date(step2Timer.timeLeft * 1000).toISOString().substr(15, 1)}</Minute2>
                      :
                      <Second1>{new Date(step2Timer.timeLeft * 1000).toISOString().substr(17, 1)}</Second1>
                      <Second2>{new Date(step2Timer.timeLeft * 1000).toISOString().substr(18, 1)}</Second2>
                  </TimerStep2>
                  <ButtonContainer>
                    <ButtonSub onClick={() => endCompetition(true)}>Avsluta</ButtonSub>
                  </ButtonContainer>
              </div>
            }

            {hasCompetitionEnded && !showAnswer &&
                <div>
                    <Title>HAR DU MINNE SOM EN HÄST?</Title>
                    <SubTitle>Tiden är slut.<br/></SubTitle>
                    <SubSubTitle>Personalen skall nu bedöma <br/> hur många rätt du fick. </SubSubTitle>
                    <ButtonContainer>
                        <ButtonSub onClick={() => showAnswerFunction()}>Visa facit</ButtonSub>
                    </ButtonContainer>
                </div>
            }

            {showAnswer && hasCompetitionEnded &&
                <div>
                    <Title>HAR DU MINNE SOM EN HÄST?</Title>
                    <SubSubTitle>FACIT:   NIVÅ {difficulty}</SubSubTitle>
                    <ButtonSub onClick={() => reset()}>Kör igen</ButtonSub>
                     <ImageContainer>
                        <Image src={imageURL}></Image>
                    </ImageContainer>
                </div>
            }
        </Container>
    )
}

export default Competition
