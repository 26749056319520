import React from 'react';
import Competition from './components/Competition';

function App() {
  return (
    <div>
      <Competition/>
    </div>
  );
}

export default App;
