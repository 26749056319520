import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
@font-face {
  font-family: 'ForsvarsmaktenSans-Stencil';
  src: url("assets/fonts/ForsvarsmaktenSans-Stencil.woff") format('woff');
}
@font-face {
  font-family: 'ForsvarsmaktenSans-Regular';
  src: url("assets/fonts/ForsvarsmaktenSans-Regular.woff") format('woff');
}
h1{
  font-family: 'ForsvarsmaktenSans-Stencil';
  font-weight: normal;
}
h2{
  font-family: 'ForsvarsmaktenSans-Regular';
  font-weight: normal;
}
body{
  background-color: #717171;
}
`

ReactDOM.render(
  <React.StrictMode>
     <GlobalStyle />
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

