import { useEffect, useState } from "react";

export const useTimer = ({initialTime, callback, inTimerCallback}) => {
  const [currentTime, setCurrentTime] = useState(0);
  const [time, setTime] = useState(initialTime);
  const [_initialTime, setInitialTime] = useState(initialTime);
  const [running, setRunning] = useState(false);

  const start = () => {
    console.log('STARTING');
    setRunning(true);
    setCurrentTime(0);
  }

  const stop = (ignoreCalback = false) => {
    setRunning(false);
    setCurrentTime(0);
    if (!ignoreCalback) callback();
  }

  const reset = () => {
    setRunning(false);
    setCurrentTime(0);
    setTime(initialTime);
  }

  useEffect(() => {
    setTime(_initialTime);
  }, [_initialTime]);

  useEffect(() => {
    if (running) {
      const timer = setInterval(() => {
        if (currentTime >= time) {
          stop();
        }
        inTimerCallback(time - currentTime);
        setCurrentTime((currentTime) => currentTime + 1);
      }, 1000);
      return () => {
        clearInterval(timer);
      };
    }
  }, [running, currentTime]);

  return {
    timeLeft: time - currentTime,
    setInitialTime,
    start,
    stop,
    reset,
  }
}